// useSearchTrackingSession.js
import { v4 as uuidv4 } from 'uuid';
import trackMixpanel, { EventTypes, getViewId } from '../resource/mixpanel.js';
import getTotalSessionDuration from '../resource/getTotalDuration.js';
import { useCallback, useRef } from 'react';

let isLocked = false;

function useSearchTrackingSession() {
  let sessionId = useRef(uuidv4());

  const start = useCallback(() => {
    if (isLocked) return;

    isLocked = true;
    getTotalSessionDuration({ id: sessionId.current, status: 'start' });
    trackMixpanel({
      type: EventTypes.SEARCH_STARTED,
      payload: {
        viewId: getViewId({ pathname: window.location.pathname }),
        sessionId: sessionId.current,
      },
    });
  }, []);

  const end = useCallback(({ keyword = '', autocompleteList = [] } = {}) => {
    if (!isLocked) return;

    isLocked = false;
    const duration = getTotalSessionDuration({
      id: sessionId.current,
      status: 'end',
    });
    trackMixpanel({
      type: EventTypes.SEARCH_ENDED,
      payload: {
        viewId: getViewId({ pathname: window.location.pathname }),
        sessionId: sessionId.current,
        keyword,
        autocompleteList,
        duration,
      },
    });
    sessionId.current = uuidv4();
  }, []);

  return {
    start,
    end,
  };
}

export default useSearchTrackingSession;
