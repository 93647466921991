// submitSearchBarQuery.js
'use strict';

import setSearch from '../action/setSearch.js';
import setRecentSearch from '../action/setRecentSearch.js';

/**
 * Submit search bar query
 * @kind action
 * @param {string} {query} - search query.
 * @return {Promise} Action promise.
 */
const submitSearchBarQuery =
  ({ query }) =>
  async dispatch => {
    if (!query) return dispatch({ type: '' });
    dispatch(setRecentSearch({ query }));
    return dispatch(setSearch({ query }));
  };

export default submitSearchBarQuery;
