// HomeSearchBar.js
'use strict';
import { connect } from 'react-redux';
import getOperationData from '../selector/getOperationData.js';
import getListData from '../selector/getListData.js';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import submitSearchBarQuery from '../action/submitSearchBarQuery.js';
import addModal from '../action/addModal.js';
import removeModals from '../action/removeModals.js';
import fetchSearchAutoComplete from '../action/fetchSearchAutoComplete.js';

import HomeSearchBar from '../component/HomeSearchBar.jsx';

const mapStateToProps = state => {
  return {
    deviceWidth: getOperationData(state, ['device'], 'width'),
    query: getOperationData(state, ['search', 'autoComplete'], 'query'),
    autoCompleteIds: getListData(state, ['search', 'autoComplete'], 'itemIds'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSearBarQuery: ({ query }) =>
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['search', 'autoComplete'],
          data: { query },
        },
      }),
    submitSearchBarQuery: ({ query }) =>
      dispatch(submitSearchBarQuery({ query })),
    openModal: ({ id }) =>
      dispatch(
        addModal({
          id,
          transitionStatus: 'opening',
        })
      ),
    closeModals: ({ ids }) =>
      dispatch(
        removeModals({
          ids,
          transitionStatus: 'closing',
          skipDelay: true,
        })
      ),
    fetchSearchAutoComplete: () => dispatch(fetchSearchAutoComplete()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchBar);
