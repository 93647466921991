// fetchSearchAutoComplete.js
'use strict';
import debounce from 'lodash/debounce';

import fetch from '../resource/customFetch.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';

import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  SET_LIST_ITEMS,
} from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';

const DEBOUNCE_DELAY = 300; // TODO: remote config
let debounceFunction = undefined;
/**
 * Fetch search auto complete
 * @kind action
 * @return {Promise} Action promise.
 */
export const fetchSearchAutoComplete = payload => async dispatch => {
  if (!debounceFunction) {
    debounceFunction = debounce(
      () => dispatch(fetchSearchAutoCompleteCore(payload)),
      DEBOUNCE_DELAY
    );
  }
  return debounceFunction(payload);
};

export const fetchSearchAutoCompleteCore = () => async (dispatch, getState) => {
  const query = getOperationData(
    getState(),
    ['search', 'autoComplete'],
    'query'
  );
  const isEmptyQuery = !query;
  const isNumberOrAlphabet = /[A-Za-z\d]/.test(query);
  const isWords = !isNumberOrAlphabet || query?.length > 1;

  if (isEmptyQuery || !isWords) {
    return dispatch({
      type: SET_LIST_ITEMS,
      payload: {
        selectPath: ['search', 'autoComplete'],
        itemIds: [],
      },
    });
  }

  const selectPath = ['search', 'autoComplete'];
  const fetchOptions = {
    method: 'GET',
    headers: {
      ...getHeaders(),
    },
  };

  const fetchUrl = getResourceUrl({ endpoint: '/search/auto-complete' });
  fetchUrl.searchParams.set('q', query);

  dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
  try {
    let response = await fetch(fetchUrl.href, fetchOptions);

    if (!response.ok) {
      await handleFetchError({ response });
    }

    const payload = await response.json();
    // username as id for display
    const userIds = payload?.users.map(item => item.username);
    const hashtagIds = payload?.hashtags.map(item => item.id);
    const itemIds = [...new Set([...userIds, ...hashtagIds])];
    if (itemIds.length) {
      dispatch({
        type: SET_LIST_ITEMS,
        payload: {
          selectPath: ['search', 'autoComplete'],
          itemIds,
        },
      });
    }
    return dispatch({
      type: SET_NETWORKING_SUCCESS,
      payload: { selectPath },
    });
  } catch (error) {
    return dispatch({
      type: SET_NETWORKING_ERROR,
      payload: { selectPath, error },
    });
  }
};

export default fetchSearchAutoComplete;
