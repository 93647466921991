// HomeSearchBar.jsx
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ModalView from '../container/ModalView.js';
import UniversalContainer from '../component/UniversalContainer.jsx';
import SearchInput from '../component/SearchInput.jsx';

import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { EMPTY_ARRAY, NULL_FUNCTION } from '../resource/defaults.js';

import media from '../style/media.js';
import { breakpoint, color, textColor } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import SearchIconSource from '../../img/ic_search_white.svg';
import {
  AUTO_COMPLETE_MODAL_ROOT_ID,
  AUTO_COMPLETE_MODAL_ID,
  SEARCH_MODAL_ID,
} from '../resource/searchConstants.js';
import useSearchTrackingSession from '../hook/useSearchTrackingSession.js';
import useUnmount from '../hook/useUnmount.js';

export const HomeSearchBar = ({
  deviceWidth = 0,
  query = '',
  setSearBarQuery = NULL_FUNCTION,
  submitSearchBarQuery = NULL_FUNCTION,
  openModal = NULL_FUNCTION,
  closeModals = NULL_FUNCTION,
  autoCompleteIds = EMPTY_ARRAY,
  shouldUseInputOnMobile = false,
  shouldAutoFocus = false,
  fetchSearchAutoComplete = NULL_FUNCTION,
}) => {
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);
  const { start: startTrackingSession, end: endTrackingSession } =
    useSearchTrackingSession();
  const isOnMobile = deviceWidth <= breakpoint.mobile;
  const shouldDisplaySubmitButton = !isOnMobile && (isFocus || !!query);
  const shouldDisplayCancelButton = isOnMobile && isFocus;
  const shouldUseSearchButtonOverlay = isOnMobile && !shouldUseInputOnMobile;

  useUnmount(() => {
    endTrackingSession({
      keyword: query,
      autocompleteList: autoCompleteIds,
    });
  });

  const handleOnBlur = useCallback(() => {
    setIsFocus(false);
    closeModals({ ids: [AUTO_COMPLETE_MODAL_ID] });
    if (!isOnMobile) {
      endTrackingSession({ keyword: query, autocompleteList: autoCompleteIds });
    }
  }, [
    setIsFocus,
    closeModals,
    endTrackingSession,
    query,
    autoCompleteIds,
    isOnMobile,
  ]);
  const handleOnFocus = useCallback(() => {
    setIsFocus(true);

    const shouldUseAutoCompleteModal = !shouldUseInputOnMobile;
    if (shouldUseAutoCompleteModal) openModal({ id: AUTO_COMPLETE_MODAL_ID });
  }, [setIsFocus, shouldUseInputOnMobile, openModal]);
  const handleOnChange = useCallback(
    e => {
      const value = e.target.value?.replace(/[\b]/g, ' ');
      setSearBarQuery({ query: value });
      fetchSearchAutoComplete();
      if (value.length >= 1 && isFocus) {
        startTrackingSession();
      }

      // For keeping auto complete modal open
      const shouldUseAutoCompleteModal = !shouldUseInputOnMobile;
      if (shouldUseAutoCompleteModal) openModal({ id: AUTO_COMPLETE_MODAL_ID });
    },
    [
      startTrackingSession,
      isFocus,
      setSearBarQuery,
      fetchSearchAutoComplete,
      shouldUseInputOnMobile,
      openModal,
    ]
  );
  const handleOnSubmit = useCallback(
    e => {
      e?.preventDefault();
      if (!query?.trim()) return;

      submitSearchBarQuery({ query });
      closeModals({ ids: [AUTO_COMPLETE_MODAL_ID, SEARCH_MODAL_ID] });
    },
    [query, submitSearchBarQuery, closeModals]
  );
  const handleClearSearch = useCallback(() => {
    setSearBarQuery({ query: '' });
  }, [setSearBarQuery]);
  const openSearchModal = useCallback(
    e => {
      e.preventDefault();
      openModal({ id: SEARCH_MODAL_ID });
    },
    [openModal]
  );

  const CancelButtonElement = useMemo(() => {
    return (
      <CancalButton>
        {t('general_cancel', {
          ns: TranslationNamespace.GENERAL,
        })}
      </CancalButton>
    );
  }, [t]);
  const SubmitButtonElement = useMemo(() => {
    return (
      <SubmitButton
        data-element_id={ButtonId.Home.ButtonSearch}
        data-tracking_payload={{
          'keyword.value': query,
        }}
        onClick={handleOnSubmit}
        $shouldShow={shouldDisplaySubmitButton}
      >
        <SubmitIconWrapper>
          <img src={SearchIconSource} alt="" />
        </SubmitIconWrapper>
      </SubmitButton>
    );
  }, [handleOnSubmit, query, shouldDisplaySubmitButton]);
  const AutoCompleteElement = useMemo(() => {
    return (
      !isOnMobile && (
        <>
          <div id={AUTO_COMPLETE_MODAL_ROOT_ID} />
          <ModalView
            id={AUTO_COMPLETE_MODAL_ID}
            rootId={`#${AUTO_COMPLETE_MODAL_ROOT_ID}`}
            modalViewStyle={{
              position: 'absolute',
              width: '100%',
            }}
            render={() => (
              <UniversalContainer
                filename="SearchBarAutoCompleteModal"
                startTrackingSession={startTrackingSession}
                endTrackingSession={endTrackingSession}
              />
            )}
          />
        </>
      )
    );
  }, [isOnMobile, startTrackingSession, endTrackingSession]);

  return (
    <StyledSearchBar>
      <SearchBarForm onSubmit={handleOnSubmit}>
        <SearchInput
          value={query}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onSearchClear={handleClearSearch}
          isFocus={isFocus}
          isOnMobile={isOnMobile}
          shouldAutoFocus={shouldAutoFocus}
        />

        {SubmitButtonElement}
        {shouldDisplayCancelButton && CancelButtonElement}
        {shouldUseSearchButtonOverlay && (
          // overlay all searchbar for Keeping input style,
          // but open search modal on mobile by click.
          <SearchModalButton onClick={openSearchModal} />
        )}
      </SearchBarForm>
      {AutoCompleteElement}
    </StyledSearchBar>
  );
};

HomeSearchBar.propTypes = {
  deviceWidth: PropTypes.number,
  query: PropTypes.string,
  autoCompleteIds: PropTypes.array,
  setSearBarQuery: PropTypes.func,
  submitSearchBarQuery: PropTypes.func,
  openModal: PropTypes.func,
  closeModals: PropTypes.func,
  shouldUseInputOnMobile: PropTypes.bool,
  shouldAutoFocus: PropTypes.bool,
  fetchSearchAutoComplete: PropTypes.func,
};

const StyledSearchBar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

const SearchBarForm = styled.form`
  display: flex;
  align-items: center;
  > label {
    padding-right: 64px;
    ${media.mobile`
      padding-right: 2px;
    `};
  }
`;

const SubmitButton = styled.button`
  visibility: ${({ $shouldShow }) => ($shouldShow ? 'visible' : 'hidden')};
  ${ResetButtonStyle}
  position: absolute;
  right: 0;
  top: 0;

  width: 60px;
  height: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: solid 1px ${color.neutral[20]};
  background-color: rgba(71, 71, 71, 0.5);
  ${media.mobile`
    height: 30px;
  `};
`;

const SubmitIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 28px;
    height: 28px;
  }
`;

const CancalButton = styled.button`
  ${ResetButtonStyle}

  width: 48px;
  height: 18px;
  margin-left: 8px;
  font-size: 14px;
  color: ${textColor.white};
  display: none;
  ${media.mobile`
    display: block;
  `}
`;

const SearchModalButton = styled.button`
  ${ResetButtonStyle}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export default HomeSearchBar;
