// setRecentSearch.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';
import { MAX_RECENT_SEARCH } from '../resource/searchConstants.js';
/**
 * Set recent search
 * @kind action
 * @param {string} {query} - new search query .
 * @param {boolean} {shouldRemove} - flag for remove specific query in list.
 * @param {boolean} {shouldRemoveAll} - flag for remove all recent search .
 * @return {Promise} Action promise.
 */
const setRecentSearch =
  ({ query, shouldRemove, shouldRemoveAll }) =>
  async (dispatch, getState) => {
    if (shouldRemoveAll) {
      return dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['search', 'recentSearches'],
          data: [],
        },
      });
    }

    if (!query) return dispatch({ type: '' });

    const recentSearches =
      getOperationData(getState(), ['search'], 'recentSearches') || [];
    // Remove exist query item
    const filteredList = recentSearches.filter(str => str !== query);
    if (shouldRemove) {
      return dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['search', 'recentSearches'],
          data: filteredList,
        },
      });
    }

    // Add to first (newest)
    const newRecentSearches = [query, ...filteredList].slice(
      0,
      MAX_RECENT_SEARCH
    );
    return dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['search', 'recentSearches'],
        data: newRecentSearches,
      },
    });
  };

export default setRecentSearch;
