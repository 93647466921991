// focusAndOpenKeyboard.js

// For ios safari autofocus input
// https://stackoverflow.com/a/55425845

/**
 * Autofocus input
 * @param {HTMLInputElement} element - input element to be autofocused.
 * @param {number} delayedMilliseconds - milliseconds when to focus target element.
 * @returns {void}
 */

function focusAndOpenKeyboard({ element, delayedMilliseconds }) {
  if (!delayedMilliseconds) {
    delayedMilliseconds = 100;
  }
  if (element) {
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    var __tempEl__ = document.createElement('input');
    __tempEl__.style.position = 'absolute';
    __tempEl__.style.top = element.offsetTop + 7 + 'px';
    __tempEl__.style.left = element.offsetLeft + 'px';
    __tempEl__.style.height = 0;
    __tempEl__.style.opacity = 0;
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__);
    __tempEl__.focus();

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function () {
      element.focus();
      element.click();
      // Remove the temp element
      document.body.removeChild(__tempEl__);
    }, delayedMilliseconds);
  }
}

export default focusAndOpenKeyboard;
