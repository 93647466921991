// setSearch.js
'use strict';
import debounce from 'lodash/debounce';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import { push } from '../action/navigationAction.js';
import getOperationData from '../selector/getOperationData.js';
import { searchType } from '../resource/searchConstants.js';

const isServer = typeof window === 'undefined';
const debounceReplaceUrl = debounce(query => {
  const url = new URL(location.href);
  if (query) {
    url.searchParams.set('q', query);
  } else {
    url.searchParams.delete('q');
  }
  history.replaceState(null, '', url.href);
}, 500); // TODO: remote config

/**
 * Set search query
 * @kind action
 * @param {string} {query} - Search query string.
 * @param {string} {hashtag} - The user views the hashtag page.
 * @param {string} {category} - Swagger category. ex: new, online
 * @return {Promise} Action promise.
 */
const setSearch =
  ({ query, hashtag, category }) =>
  async (dispatch, getState) => {
    const selectPath = ['search'];

    let hashtagHistory = [];

    if (hashtag) {
      hashtagHistory = [
        hashtag,
        ...(getOperationData(getState(), ['search'], 'hashtagHistory') || []),
      ];
    }

    if (!isServer) {
      const isOnSearchPage = location.pathname.startsWith('/search');
      const isGoingToSearchPage = !isOnSearchPage && query;
      if (isGoingToSearchPage) {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.set('q', query);
        dispatch(
          push(`/search/${searchType.ALL}?${urlSearchParams.toString()}`)
        );
      }

      debounceReplaceUrl(query);
    }

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath,
        data: {
          autoComplete: { query },
          ...(typeof query === 'string' ? { query } : null),
          ...(typeof category === 'string' ? { category } : null),
          ...(hashtag
            ? { hashtagHistory: [...new Set(hashtagHistory)].slice(0, 3) } // remove duplicates
            : null),
        },
      },
    });
  };

export default setSearch;
