// getTotalDuration.js
'use strict';

const recordingMap = new Map();

/**
 * Get total duration time
 * @param {string} id - ID to make each process independent
 * @param {string('start'|'pause'|'end')} status - status
 * @return {number | undefined}  total duration time
 */
const getTotalDuration = ({ id, status }) => {
  if (!id) return;

  if (status === 'start') {
    if (recordingMap.get(id)?.currentStatus === 'start') return;

    const startTime = Date.now();
    recordingMap.set(id, {
      ...recordingMap.get(id),
      currentStatus: 'start',
      startTime,
    });
  } else if (status === 'end') {
    let totalTime = recordingMap.get(id)?.totalTime || 0;
    const startTime = recordingMap.get(id)?.startTime || 0;
    if (startTime !== 0) {
      const diff = Math.floor((Date.now() - startTime) / 1000);
      totalTime += diff;
    }
    recordingMap.delete(id);
    return totalTime;
  } else {
    const startTime = recordingMap.get(id)?.startTime || 0;
    if (startTime !== 0) {
      const diff = Math.floor((Date.now() - startTime) / 1000);
      const currentTotalTime = recordingMap.get(id)?.totalTime || 0;
      const newTotalTime = currentTotalTime + diff;

      recordingMap.set(id, {
        ...recordingMap.get(id),
        totalTime: newTotalTime,
      });
    }
    recordingMap.set(id, {
      ...recordingMap.get(id),
      currentStatus: status,
      startTime: 0,
    });
  }
};

export default getTotalDuration;
