// SearchInput.jsx
import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ResetButtonStyle from '../style/ResetButtonStyle.js';
import { color, textColor } from '../style/variables.js';

import focusAndOpenKeyboard from '../resource/focusAndOpenKeyboard.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import SearchIconSource from '../../img/ic_search_white.svg';
import CloseIconSource from '../../img/ic_search_close.svg';
import { ButtonId } from '../resource/mixpanel.js';

const defaultFunction = () => null;

export const SearchInput = ({
  onChange = defaultFunction,
  onFocus = defaultFunction,
  onBlur = defaultFunction,
  onSearchClear = defaultFunction,
  value = '',
  searchColor = textColor.white,
  isFocus = false,
  isOnMobile = false,
  shouldAutoFocus = false,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  useEffect(() => {
    if (shouldAutoFocus && inputRef.current) {
      inputRef.current.focus();
      focusAndOpenKeyboard({
        element: inputRef.current,
        delayedMilliseconds: 300,
      });
    }
  }, [shouldAutoFocus]);

  const hasValue = value != null && value !== '';
  const shouldDisplayClearButton = isOnMobile ? isFocus && hasValue : hasValue;
  const shouldDisplaySearchIcon = isOnMobile || (!isFocus && !hasValue);
  // Desktop and tablet active style
  const shouldDisplayActiveStyle = !isOnMobile && (isFocus || hasValue);

  return (
    <StyledSearchInput
      searchColor={searchColor}
      isOnMobile={isOnMobile}
      shouldActive={shouldDisplayActiveStyle}
    >
      {shouldDisplaySearchIcon && (
        <IconWrapper>
          <img src={SearchIconSource} alt="" />
        </IconWrapper>
      )}
      <input
        ref={inputRef}
        value={value}
        type="text"
        placeholder={t('tabbar_search', {
          ns: TranslationNamespace.GENERAL,
        })}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {shouldDisplayClearButton && (
        <ClearInputButton
          type="button"
          onMouseDown={e => e.preventDefault()}
          onClick={onSearchClear}
          data-element_id={ButtonId.Search.ButtonSearchCancel}
        >
          <img src={CloseIconSource} width={20} height={20} alt="" />
        </ClearInputButton>
      )}
    </StyledSearchInput>
  );
};

SearchInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchColor: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSearchClear: PropTypes.func,
  isFocus: PropTypes.bool,
  isOnMobile: PropTypes.bool,
  shouldAutoFocus: PropTypes.bool,
};

export const IconWrapper = styled.div`
  flex: none;
  height: 20px;

  & > img {
    height: 100%;
    vertical-align: top;
  }
`;

export const StyledSearchInput = styled.label`
  position: relative;
  width: 100%;
  height: ${({ isOnMobile }) => (isOnMobile ? 30 : 40)}px;
  border-radius: ${({ isOnMobile }) => (isOnMobile ? 15 : 20)}px;
  margin-bottom: 0;
  padding: 4px 12px 4px 16px;
  background-color: ${({ shouldActive }) =>
    shouldActive ? color.black : color.neutral[20]};
  border: solid 1px ${color.neutral[20]};

  display: flex;
  align-items: center;

  & > input[type='text'] {
    flex: auto;
    outline: none;
    border: none;
    padding: 0px 8px 0px 4px;
    height: 100%;
    width: 100%;
    background: none;
    font-size: 16px;
    font-weight: 400;
    color: ${({ searchColor }) => searchColor};
    line-height: 16px;
    ::placeholder {
      color: ${textColor.white};
    }
    :focus::placeholder {
      color: ${textColor.black};
    }
  }
`;

const ClearInputButton = styled.button`
  ${ResetButtonStyle}
  border-radius: 50%;
  width: 20px;
  height: 20px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(71, 71, 71, 0.8);
`;

export default memo(SearchInput);
